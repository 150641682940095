<template>
  <div class="headerTop-links py-3" :class="isScroll && 'sticky'" >
    <div class="container">
      <div class="row">
        <div class="col-12">
          <ul class="list-inline mb-0 d-flex flex-wrap" style="align-items: center;">

            <li class="list-inline-item m-0 home-icon" v-if="isFCLAgent && currentPage !== 'pending-info'">
              <a href="/external-booking" onclick="return false;" @click="gotoExternalPage" title="External Booking">
                <i class="fas fa-th-list"></i>
              </a>
            </li>
            <li class="ml-3 home-icon" v-if="isFCLAgent && currentPage !== 'booking-cancel'">
              <a href="/cancel-booking" onclick="return false;" @click="gotoCancelPage" title="Cancel Booking">
                <i class="fa fa-ban"></i>
              </a>
            </li>
            <li class="ml-3 home-icon" v-if="isFCLAgent">
              <a href="#" onclick="return false;" @click="showBookingCheckModal" title="Check Booking">
                <i class="fa fa-check-circle"></i>
              </a>
            </li>
            <li v-if="agencyUser" style="align-self: center;" class="ml-1">
              <b-button @click="agencyLogout" variant="outline-light" size="sm" class="w-100">{{$t('login.log-off') }}</b-button>
            </li>
            <li class="list-inline-item m-0 home-icon" v-if="isNotHomePage">
              <router-link :to="logoURL">
                <i class="fas fa-home"></i>
              </router-link>
            </li>

            <li class="list-inline-item topSocialLink">
              <ul class="list-inline top-social">
                <li class="list-inline-item" v-if="showInstagramLink">
                  <a :href="instagramLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-instagram"></i></a>
                </li>
                <li class="list-inline-item" v-if="showFacebookLink">
                  <a :href="facebookLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-facebook-f"></i></a>
                </li>
                <li class="list-inline-item" v-if="showTwitterLink">
                  <a :href="twitterLink" target="_blank" title="נפתח בחלון חדש"><i class="fab fa-x-twitter"></i></a>
                </li>
              </ul>
            </li>
            <li class="list-inline-item topFollowUs" v-if="showFacebookLink">
              <a class="" :href="facebookLink">{{ $t("top-header.follow-us") }} <i class="fas fa-cog"></i></a>
            </li>
            <li class="list-inline-item topCallUs" v-if="device === 'desktop' && !isFCLAgent">
              <a class="w-100 ml-2" :href="`tel: 03-5260505`" v-if="isEmalonMode">
                <i class="fas fa-phone-alt"></i>
                <span class="phone-number"> {{ '03-5260505' }}</span>
              </a>
              <a class="w-100" :href="`tel:${phoneNumber}`" >
                <i class="fas fa-phone-alt"></i>&nbsp;
                <span class="phone-number"> {{ phoneNumber }}</span>
              </a>
              <span> : {{ $t("top-header.vacation-in-israel") }}</span>
            </li>
            <li class="list-inline-item topCallUs" v-if="device === 'desktop' && showVacationPhone && !isFCLAgent">
              <a class="w-100" :href="`tel:${whiteLabel.vacationPhoneNumber}`" >
                <i class="fas fa-phone-alt"></i>
                <span class="phone-number"> {{ whiteLabel.vacationPhoneNumber }}</span>
                <span> : {{ $t("top-header.vacation-abroad") }}</span>
              </a>
            </li>
            <li class="list-inline-item d-flex topCallUs" :class="rtlClassName" style="place-items: inherit;" v-if="device !== 'desktop' && !isFCLAgent">
              <b-button class="extra-phones" :href="`tel:${phoneNumber}`"><i class="fas fa-phone-alt"></i> &nbsp;{{ $t("top-header.service-center") }}</b-button>
              <!-- <b-dropdown variant="primary" size="sm" ref="dropdown" no-caret>
                <template #button-content>
                  {{ $t("top-header.service-center") }}  &nbsp;<i class="fas fa-phone-alt"></i>
                </template>
                <b-dropdown-item :href="`tel:${phoneNumber}`">
                  <div class="display-flex">
                    <div class="pl-0 pr-2 py-1">
                      <b-button class="w-100" :href="`tel:${phoneNumber}`" size="sm" variant="primary">{{ phoneNumber }}: {{ $t("top-header.vacation-in-israel") }}</b-button>
                    </div>
                  </div>
                </b-dropdown-item>
                <b-dropdown-item :href="`tel:${whiteLabel.vacationPhoneNumber}`" v-if="showVacationPhone">
                  <div class="display-flex">
                    <div class="pl-0 pr-2 py-1">
                      <b-button class="w-100" :href="`tel:${whiteLabel.vacationPhoneNumber}`" size="sm" variant="primary">{{ whiteLabel.vacationPhoneNumber }}: {{ $t("top-header.vacation-abroad") }}</b-button>
                    </div>
                  </div>
                </b-dropdown-item>
              </b-dropdown> -->
            </li>
          </ul>
        </div>
      </div>
    </div>

    <b-modal id="checkTzNumber" centered :dir="lang == 'he' ? 'rtl' : 'ltr'" :no-close-on-backdrop="true" :ok-only="true">
      <template >
        <div class="d-flex flex-grow-1 align-items-baseline" :class="[lang == 'he' ? 'text-right' : 'text-left']">
          <h3 class="m-auto">{{ $t("check-agent-booking.check-id-title") }}</h3>
        </div>
      </template>
      <template>
        <div class="w-100">

          <b-form style="display:flex;" @submit.stop.prevent>
            <b-input-group class="m-0">
              <b-input-group-prepend is-text>
                <i class="fa fa-user"></i>
              </b-input-group-prepend>
              <b-form-input id="tz-number" :placeholder="$t('check-agent-booking.tz-number')" v-model="tzNumber"></b-form-input>
            </b-input-group>
            <b-button variant="primary" @click="requestTzNumber">{{$t('check-agent-booking.check')}}</b-button>
          </b-form>

          <div v-if="isLoading && !tzNumberCheckResult" class="w-100 text-center my-5"><b-spinner large></b-spinner></div>

          <div v-if="tzNumberCheckResult" :class="`${lang}-result-body`">
            <b-alert :class="`${lang}-alert`" show dismissible variant="success" v-if="tzNumberCheckResult.isApproved" class="mt-4"><i class="fa fa-check"></i>{{ $t('check-agent-booking.approved') }}</b-alert>
            <b-alert :class="`${lang}-alert`" show dismissible variant="danger" v-else><i class="fa fa-check"></i>{{ $t('check-agent-booking.not-approved') }}</b-alert>
            <p v-for="(item, inx) in tzNumberCheckResult.usersHistoryList" :key="inx">
              <span v-if="item.status" style="color: green;">{{ $t('check-agent-booking.redemption') }} {{$t('check-agent-booking.subsidy')}} {{ item.confirmationNum }} {{ item.year }}</span>
              <span v-if="!item.status" style="color: red;">{{ $t('check-agent-booking.redemption') }} {{$t('check-agent-booking.canceled')}} {{ item.confirmationNum }} {{ item.year }}</span>
            </p>
          </div>

        </div>
      </template>
      <template #modal-footer="{ ok }">
        <b-button size="lg" variant="success" @click="ok();">
          {{ $t("booking.ok") }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  // BDropdown,
  // BDropdownItem,
  BForm, BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BAlert,
  BSpinner,
} from 'bootstrap-vue';
// import mixinTopHeader from './mixinTopHeader';
import TemplateTopHeader from './TemplateTopHeader';

export default {
  name: 'TopHeaderTheme0',
  mixins: [TemplateTopHeader],
  components: {
    BAlert,
    BButton,
    // BDropdown,
    // BDropdownItem,
    BForm,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BSpinner,
    // ContentWrapper: () => import('@/components/content/ContentWrapper')
  },
  // mixins: [mixinTopHeaderd],
};
</script>
<style scoped>
li.home-icon {
  height: 24px;
  border-right: none !important;
}
li.home-icon > a {
  font-size: 1.2rem;
}
li.phone-number-rtl {
  direction: ltr;
}
ul {
  font-family: "Poppins", sans-serif;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}
.list-inline {
  padding-right: 0;
}
.landing-part .logo {
  max-width: 230px;
  float: left;
  margin-left: 6rem;
}
.landing-part ul li img {
  max-width: 34px;
  margin-right: 5px;
}
.landing-part ul {
  line-height: 40px;
}
.landing-part .header_rightbox_bottom {
  display: block;
  background-color: #fff;
  padding: 8px 20px 9px 30px;
  position: absolute;
  top: 50px;
  box-shadow: 0px 0px 6px rgb(0 0 0 / 18%);
  font-size: 1.2em;
}
.landing-part .header_rightbox_bottom a {
  color: #0061ab;
}
.landing-part .header_rightbox_bottom:before {
  content: "";
  position: absolute;
  left: -45px;
  top: 0;
  width: 0;
  height: 0;
  border-top: 45px solid white;
  border-left: 45px solid transparent;
  z-index: 0;
}

.topFollowUs > a:hover, .topCallUs > a:hover {
  color: #adf;
}
.phone-number {
  direction: ltr;
  display: inline-flex;
}
a:hover .phone-number {
  text-decoration: underline;
}
@media (max-width: 767px) {
  ul {
    padding: 0;
  }
  .landing-part .logo {
    margin: 20px 0 35px 3rem;
    max-width: 160px;
  }
 .landing-part .header_rightbox_bottom {
    display: initial;
    top: 70px;
  }
}
</style>
<style>

.he-alert {
  direction: rtl;
  text-align: right;
  padding-left: 4rem;
  padding-right: 1rem;
}
.he-alert button.close {
  left: 0;
  right: auto;
}
.he-result-body p {
  direction: rtl;
  text-align: right;
}
div[class*='result-body'] p {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.headerTop-links .btn.dropdown-toggle{
  background: #0061ab;
  border: none;
  padding: 0;
  font-size: inherit;
}
.headerTop-links .btn.dropdown-toggle:hover{
  color: yellow;
}
</style>
